import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import { Field, change as changeFormField, reduxForm } from 'redux-form';

import { RECAPTCHA_SITE_KEY } from '../../config/recaptcha';
import { REGISTRATION_INVITATION_CODE } from '../../config/registrationInvitationCode';
import {
  STRIPE_DATA_REGISTRATION_ENABLED,
  STRIPE_PROMOTION_CODE_ENABLED,
  STRIPE_SUBSCRIPTION_TRIAL_DAYS
} from '../../config/stripe';
import StripeCreditCardForm from '../subscription/StripeCreditCardForm';
import withStripeForm from '../subscription/WithStripeForm';

class RegistrationForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    error: PropTypes.string
  };

  componentDidMount() {
    if (!!RECAPTCHA_SITE_KEY) {
      loadReCaptcha(RECAPTCHA_SITE_KEY, () => {
        // callback
      });
    }
  }

  handleLocalSubmit = async event => {
    if (!!STRIPE_DATA_REGISTRATION_ENABLED) {
      event.preventDefault();

      let stripeData = null;
      if (
        typeof this.props.getStripeFormComponent === 'function' &&
        !!this.props.getStripeFormComponent() &&
        typeof this.props.getStripeFormComponent().handleSubmit === 'function'
      ) {
        stripeData = await this.props
          .getStripeFormComponent()
          .handleSubmit(event);
      }

      if (
        !stripeData ||
        (!!stripeData &&
          (!stripeData.paymentMethod ||
            (!!stripeData.paymentMethod && !stripeData.paymentMethod.id)))
      ) {
        // error
        return;
      }

      this.stripeDataInput.value = JSON.stringify(stripeData);
      this.props.dispatch(
        changeFormField('account', 'stripeData', JSON.stringify(stripeData))
      );
    }

    return this.props.handleSubmit(event);
  };

  handleChangeFirstName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('account', 'name', name));
  };

  handleChangeLastName = event => {
    const name = this.firstNameInput.value + ' ' + this.lastNameInput.value;
    this.nameInput.value = name;
    this.props.dispatch(changeFormField('account', 'name', name));
  };

  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.recaptchaInput.value = recaptchaToken;
    this.props.dispatch(changeFormField('account', 'captcha', recaptchaToken));
  };

  updateToken = () => {
    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  };

  renderRecaptcha = data => {
    return (
      <>
        <ReCaptcha
          ref={ref => (this.recaptcha = ref)}
          sitekey={RECAPTCHA_SITE_KEY}
          action="registration"
          verifyCallback={this.verifyCallback}
        />
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          id={`account_${data.input.name}`}
          ref={ref => (this.recaptchaInput = ref)}
        />
      </>
    );
  };

  renderHiddenField = data => {
    return (
      <input
        {...data.input}
        type={'hidden'}
        step={data.step}
        id={`account_${data.input.name}`}
        ref={data.innerRef || undefined}
      />
    );
  };

  renderField = data => {
    data.input.className = 'form-control';

    const isInvalid = data.meta.touched && !!data.meta.error;
    if (isInvalid) {
      data.input.className += ' is-invalid';
      data.input['aria-invalid'] = true;
    }

    if (this.props.error && data.meta.touched && !data.meta.error) {
      data.input.className += ' is-valid';
    }

    // Fixing the appearance of checkboxes on the registration form
    if (data.type === 'checkbox') {
      return (
        <div className={`form-group checkbox`}>
          <input
            {...data.input}
            type={data.type}
            step={data.step}
            required={data.required}
            placeholder={data.placeholder}
            id={`account_${data.input.name}`}
            ref={data.innerRef || undefined}
            onChange={data.onChange || data.input.onChange}
          />
          <label
            htmlFor={`account_${data.input.name}`}
            className="form-control-label"
          >
            {data.label || data.input.name}
          </label>
          {isInvalid && (
            <div className="invalid-feedback">{data.meta.error}</div>
          )}
        </div>
      );
    }

    return (
      <div className={`form-group`}>
        <label
          htmlFor={`account_${data.input.name}`}
          className="form-control-label"
        >
          {data.label || data.input.name}
        </label>
        <input
          {...data.input}
          type={data.type}
          step={data.step}
          required={data.required}
          placeholder={data.placeholder}
          id={`account_${data.input.name}`}
          ref={data.innerRef || undefined}
          onChange={data.onChange || data.input.onChange}
        />
        {isInvalid && <div className="invalid-feedback">{data.meta.error}</div>}
      </div>
    );
  };

  render() {
    return (
      <form onSubmit={this.handleLocalSubmit}>
        <Field
          component={this.renderHiddenField}
          name="name"
          label="Name"
          type="text"
          placeholder="e.g. Jane Doe"
          required={true}
          innerRef={ref => (this.nameInput = ref)}
        />
        <Field
          component={this.renderField}
          name="firstName"
          label="First Name"
          type="text"
          placeholder="e.g. Jane"
          required={true}
          innerRef={ref => (this.firstNameInput = ref)}
          onChange={this.handleChangeFirstName}
        />
        <Field
          component={this.renderField}
          name="lastName"
          label="Last Name"
          type="text"
          placeholder="e.g. Doe"
          required={true}
          innerRef={ref => (this.lastNameInput = ref)}
          onChange={this.handleChangeLastName}
        />
        <Field
          component={this.renderField}
          name="company"
          label="Company"
          type="text"
          placeholder="e.g. Acme Corp."
        />
        <Field
          component={this.renderField}
          name="email"
          label="Email"
          type="email"
          placeholder="janedoe@example.com"
          required={true}
        />
        <Field
          component={this.renderField}
          name="plainPassword"
          label="Password"
          type="password"
          placeholder=""
          required={true}
        />
        {!!REGISTRATION_INVITATION_CODE && (
          <Field
            component={this.renderField}
            name="invitation_code"
            label="Invitation Code"
            type="text"
            placeholder="Invitation Code"
            required={true}
          />
        )}
        {!!RECAPTCHA_SITE_KEY && (
          <Field
            component={this.renderRecaptcha}
            name="captcha"
            type="hidden"
            required={true}
          />
        )}

        {!STRIPE_DATA_REGISTRATION_ENABLED &&
          !!STRIPE_SUBSCRIPTION_TRIAL_DAYS && (
            <p className="text-center">
              FREE {STRIPE_SUBSCRIPTION_TRIAL_DAYS} day trial! No credit card
              required!
            </p>
          )}

        {!!STRIPE_DATA_REGISTRATION_ENABLED && (
          <>
            <div className={`form-group`}>
              <label
                htmlFor={`account_stripeCard`}
                className="form-control-label"
              >
                Credit Card
              </label>
              {this.props.stripeFormComponent}
            </div>
            <Field
              component={this.renderHiddenField}
              name="stripeData"
              type="text"
              required={true}
              innerRef={ref => (this.stripeDataInput = ref)}
            />
          </>
        )}
        {!!STRIPE_DATA_REGISTRATION_ENABLED &&
          !!STRIPE_PROMOTION_CODE_ENABLED && (
            <Field
              component={this.renderField}
              name="stripePromotionCode"
              label="Promo Code"
              type="text"
              required={false}
            />
          )}
        <Field
          component={this.renderField}
          name="didAgreeToTerms"
          label={
            <span>
              I agree to the{' '}
              <a href="https://www.lista.io/terms-of-use" target="_blank">
                Terms of Service.
              </a>
            </span>
          }
          type="checkbox"
          placeholder=""
          required={true}
        />
        <button type="submit" className="btn btn-block btn-secondary mb-3">
          Create New Account
        </button>
      </form>
    );
  }
}

const ReduxRegistrationForm = reduxForm({
  form: 'account',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(RegistrationForm);

export default !!STRIPE_DATA_REGISTRATION_ENABLED
  ? withStripeForm(ReduxRegistrationForm, StripeCreditCardForm, {
      //attachPaymentMethod: (paymentMethod) => { return new Promise((resolve, reject) => resolve(paymentMethod));) },
      cardElementOptions: () => {
        return {
          hidePostalCode: false
        };
      }
    })
  : ReduxRegistrationForm;
